<script lang="ts">
  import type { LogoGroup } from '$lib/contentful/models/logo-group';
  import ContentfulImage from '../contentful-image/contentful-image.svelte';
  import Text from '../text/text.svelte';

  export let content: LogoGroup | undefined;
</script>

{#if content}
  {@const { title, background, logos, entityId, display } = content}
  {#if display === 'scrolling'}
    <div class="logo-marquee {background}">
      <div class="marquee-group">
        {#each logos as logo}
          <ContentfulImage
            fieldId="logo"
            {entityId}
            class="h-6"
            height={24}
            src={logo.url}
            alt={logo.title}
          />
        {/each}
      </div>
      <div aria-hidden="true" class="marquee-group">
        {#each content.logos as logo}
          <ContentfulImage
            fieldId="logo"
            {entityId}
            class="h-6"
            height={24}
            src={logo.url}
            alt={logo.title}
          />
        {/each}
      </div>
    </div>
  {:else}
    <div class="flex flex-col gap-6 py-10">
      <hr class="border-indigo-100/20" />
      <div class="flex justify-center">
        <Text as="p" variant="body-24">
          {title}
        </Text>
      </div>
      <div class="flex justify-evenly py-10 flex-wrap gap-8">
        {#each logos as logo}
          <ContentfulImage
            fieldId="logo"
            {entityId}
            class="h-6"
            height={24}
            src={logo.url}
            alt={logo.title}
          />
        {/each}
      </div>
    </div>
  {/if}
{/if}

<style lang="postcss">
  .logo-marquee {
    @apply py-10 flex overflow-hidden select-none gap-16;
  }

  .logo-marquee.light {
    @apply bg-white;
  }

  .marquee-group {
    @apply flex shrink-0 items-center justify-around gap-16 min-w-full animate-logo-marquee;
  }
</style>
